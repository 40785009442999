import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SensorsList } from "./SensorsList";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "список-тэгов-для-датчиков"
    }}>{`Список тэгов для датчиков`}</h1>
    <h3 {...{
      "id": "системные"
    }}>{`Системные`}</h3>
    <SensorsList system={true} mdxType="SensorsList" />
    <h3 {...{
      "id": "не-системные"
    }}>{`Не системные`}</h3>
    <p>{`Если присутствует `}<strong parentName="p">{`n`}</strong>{` - это значит возможна любая цифра.`}</p>
    <p>{`Например: `}<strong parentName="p">{`in_d`}{`[n]`}</strong>{` - это `}<strong parentName="p">{`in_d0`}</strong>{`, `}<strong parentName="p">{`in_d1`}</strong>{`, `}<strong parentName="p">{`in_d2`}</strong>{` и т.д.`}</p>
    <SensorsList system={false} mdxType="SensorsList" />
    <blockquote>
      <p parentName="blockquote">{`Страница дополняется...`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      